.paper-user-container {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--gray-ligth);
    border-radius: 10px;
    padding: 8px 16px;
}

.paper-user-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.paper-user-info {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    width: 320px;
}

.paper-user-info label {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.paper-user-info span {
    color: var(--gray);
}

@media only screen and (max-width: 768px) {
    .paper-user-info {
        width: 60%;
    }
}