.modal-redirect-service-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    width: 50%;
}

.modal-redirect-service-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-redirect-service-title-content h2 {
    margin: 0;
    margin-bottom: 6px;
}

.modal-redirect-service-content {
    padding: 0px 16px;
}

.modal-redirect-service-title {
    font-weight: bold;
    margin-bottom: 6px;
}

.modal-redirect-service-solicitation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 8px 16px;
}

.modal-redirect-service-solicitation-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.modal-redirect-service-solicitation-footer {
    display: flex;
    color: #A3A3A3;
    font-size: 11px;
}

.modal-redirect-service-list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
}

.new-value-content {
    margin-top: 16px;
}

.new-value-content span {
    font-weight: bold;
}

.new-value-button-group {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 6px;
    margin-top: 6px;
}

.new-value-button-button {
    background: transparent;
    border: 1px solid #009CF5;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 13px;
    cursor: pointer;
}

.new-value-button-button.active {
    background: #009CF5;
    color: white;
    font-weight: bold;
}

.modal-redirect-service-providers-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    row-gap: 16px;
    margin-top: 8px;
}