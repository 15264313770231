.paper-company-user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--gray-ligth);
    border-radius: 10px;
    padding: 8px 16px;
}

.paper-comapny-user-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.paper-company-user-data {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    min-width: 200px;
}

.paper-company-user-data label {
    font-weight: bold;
}

.paper-company-user-data span {
    font-size: var(--fs-s);
    color: var(--gray);
}

