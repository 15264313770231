.paper-service-radius-container {
    background-color: var(--white);
    border: 1px solid var(--gray-ligth);
    border-radius: 8px;
    color: var(--blue);
    font-size: var(--fs-m);
    font-weight: bold;
    padding: 8px 16px;
    cursor: pointer;
}

.paper-service-radius-container:hover {
    border-color: var(--blue);
}

.paper-service-radius-container.active {
    background-color: var(--blue);
    color: var(--white)
}