.paper-new-solicitation-container {
    border: 1px solid var(--gray-ligth);
    border-radius: 10px;
    padding: 16px;
    margin-top: 8px;
}

.paper-new-solicitation-content {
    margin-bottom: 16px;
}

.paper-new-solicitation-title {
    font-size: var(--fs-l);
    font-weight: bold;
    color: var(--blue);
    text-align: center;
    margin-bottom: 16px;
}

.paper-new-solicitation-info {
    background-color: #fffb9f;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    font-size: var(--fs-s);
}

.paper-new-solicitation-info label {
    font-weight: bold;
}

.paper-new-solicitation-info button {
    background-color: transparent;
    border: none;
    margin-top: 8px;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.paper-new-solicitation-button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
}

.paper-new-solicitation-price {
    font-weight: bold;
    color: var(--blue);
}