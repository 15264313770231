.access-container {
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    padding: 32px;
    height: calc(100% - 60px - 64px);
}

.access-content {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    width: 100%;
    border-radius: 20px;
    padding: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.access-users-content {
    font-size: var(--fs-m);
    display: flex;
    flex-direction: row;
}

.access-users-content h1 {
    margin: 0;
    margin-bottom: 8px;
}

.access-users-list {
    margin-top: 32px;
}

.access-users-title {
    font-weight: bold;
    font-size: var(--fs-m);
}

.access-user-hr {
    width: 1px;
    height: 100%;
    background-color: var(--gray);
    margin-left: 32px;
}

.access-users-grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    margin-top: 8px;
}

.access-user-tips-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 32px;
}

.access-user-title-contet button{
    margin-left: 8px;
    background-color: transparent;
    border: none;
    color: var(--blue);
    cursor: pointer;
}

.access-users-forms-content {
    margin-bottom: 16px;
}

.access-users-forms-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    margin: 8px 0;
}