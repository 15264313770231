.home-container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 60px);
}

.home-menu-container {
    width: calc(30% - 32px);
    height: calc(100% - 32px);
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-menu-container-done {
    width: calc(30% - 32px);
    height: calc(100% - 32px);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-menu-container-done img {
    width: 60%;
}

.home-menu-container-done span {
    font-size: var(--fs-l);
    color: var(--black);
    margin-top: 16px;
}

.home-menu-item {
    height: 100%;
    padding: 8px;
}

.home-menu-item-title {
    font-size: var(--fs-m);
    font-weight: bold;
}

.home-map-container {
    width: 70%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .home-menu-container-done {
        width: 100%;
    }

    .home-menu-container {
        width: 100%;
    }
}