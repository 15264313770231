.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 80px;
    height: 100%;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-content {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sidebar-button {
    display: flex;
    flex-direction: column;
    width: 80px;
}

.sidebar-button.MuiIconButton-root {
    padding: 8px;
}

.sidebar-button span {
    color: var(--gray-4);
    font-size: var(--fs-s);
    margin-top: 4px;
}

.sidebar-button svg {
    color: var(--gray-4);
}

.sidebar-button:hover span, svg {
    color: var(--white);
}

.sidebar-button.active span, svg {
    color: var(--white);
}

.sidebar-button:hover svg {
    color: var(--white);
}

.sidebar-icon-button {
    border-radius: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 3px 0;
}

.sidebar-icon-button.active {
    background-color: var(--white);
}

.sidebar-icon-button.active svg {
    color: var(--blue);
}

.sidebar-content {
    display: flex;
    flex-direction: column;
}