.services-container {
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    padding: 32px;
    height: calc(100% - 60px - 64px);
}

.services-content {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    width: 100%;
    border-radius: 20px;
    padding: 32px;
}

.services-body {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: calc(100% - 16px - 32px);
}

.services-list-grid {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding-right: 32px;
}

.new-services-list-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 16px;
    margin-top: 8px;
}

.new-service-radius-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 16px;
    margin-top: 8px;
}

.new-service-fee-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    margin-top: 12px;
}

.new-services-forms-item {
    margin-top: 16px;
}

.new-services-forms-title,
.new-service-fee-simulation-container {
    font-size: var(--fs-m);
    font-weight: bold;
}

.new-service-fee-simulation-container {
    margin-top: 8px;
    background-color: wheat;
    padding: 16px;
    border-radius: 10px;
}

.new-service-fee-simulation-result li{
    font-weight: initial;
}