.historic-container {
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    padding: 32px;
    height: calc(100% - 60px - 64px);
}

.historic-content {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    width: 100%;
    border-radius: 20px;
    padding: 32px;
}

.historic-head h1 {
    margin: 0;
    margin-bottom: 24px;
}

.historic-head-content {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 16px;
    width: 50%;
}

.historic-body {
    height: calc(100% - 107px - 24px);
}

.historic-body-hr {
    width: 100%;
    height: 1px;
    background-color: var(--gray-ligth);
    margin: 24px 0px;
}

.historic-body-empty-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.historic-body-empty-content img {
    object-fit: cover;
    height: 60%;
    margin-bottom: 16px;
}

.historic-body-empty-content span {
    font-weight: bold;
}