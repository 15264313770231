.providers-container {
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    padding: 32px;
    height: calc(100% - 60px - 64px);
}

.providers-content {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    width: 100%;
    border-radius: 20px;
    padding: 32px;
}

.providers-body {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: calc(100% - 16px - 32px);
}

.providers-list-container {
    height: 100%;
    padding-right: 32px;
}

.providers-list-content {
    margin-top: 16px;
    height: calc(100% - 40px - 16px);
    overflow-y: auto;
}

/* Estilo para o corpo do scroll */
.providers-list-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Estilo para o trilho do scroll */
.providers-list-content::-webkit-scrollbar-track {
    background: #E5E5E5;
}

/* Estilo para o polegar do scroll */
.providers-list-content::-webkit-scrollbar-thumb {
    background-color: #009CF5;
}

/* Estilo ao passar o mouse sobre o polegar do scroll */
.providers-list-content::-webkit-scrollbar-thumb:hover {
    background-color: #009CF5;
}

.providers-list-grid {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    height: 500px;
}

.new-provider-forms-title {
    font-size: var(--fs-m);
    font-weight: bold;
}

.new-provider-forms-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    margin-top: 8px;
}

.new-provider-forms-item {
    margin-top: 16px;
}

.new-provider-forms-title-group {
    display: flex;
    flex-direction: column;
}
