.paper-service-dense-container {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid var(--gray-ligth);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
}

.paper-service-dense-container:hover {
    border-color: var(--blue);
}

.paper-service-dense-container.active {
    background-color: var(--blue);
}

.paper-service-dense-container.active * {
    color: var(--white);
}

.paper-service-dense-container span {
    margin-top: 8px;
    font-weight: bold;
}