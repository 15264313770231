.paper-service-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--gray-ligth);
    border-radius: 10px;
    padding: 12px 16px;
    cursor: pointer;
}

.paper-service-container:hover {
    border-color: var(--blue);
}

.paper-service-container.active {
    border-color: var(--blue);
    background-color: var(--blue);
}

.paper-service-container.active * {
    color: var(--white) !important;
}

.paper-service-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    width: calc(100% - 16px);
}

.paper-service-body {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.paper-service-title {
    font-size: var(--fs-m);
    font-weight: bold;
    color: var(--blue);
}

.paper-service-subtitle {
    color: var(--gray);
    margin-top: 3px;
}