.header-container {
    background-color: var(--black);
    height: 60px;
    width: 100%;
}

.header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 32px;
    height: 100%;
}

.header-title {
    font-size: var(--fs-m);
    color: var(--white);   
}

.header-button {
    background-color: transparent;
    border: None;
    color: var(--white);
    font-size: var(--fs-m);
    cursor: pointer;
}