.paper-service-proposal-container {
    border: 1px solid var(--gray-ligth);
    border-radius: 10px;
    padding: 8px;
}

.paper-service-proposal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.paper-service-status-content {
    display: flex;
    flex-direction: column;
}

.paper-service-value-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.paper-service-value-content label {
    font-weight: bold;
    font-size: var(--fs-m);
}

.paper-service-value-content span {
    color: var(--blue);
    font-weight: bold;
    font-size: var(--fs-l);
}

.paper-service-avatar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
}

.paper-service-consumer-content {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
}

.paper-service-consumer-content label {
    font-weight: bold;
    font-size: var(--fs-m);
}

.paper-service-consumer-content span {
    color: var(--gray);
    font-size: var(--fs-s);
}

.paper-service-status {
    font-size: var(--fs-s);
}

.paper-service-status.waiting {
    color: var(--gray);
}

.paper-service-status.success {
    color: var(--green);
}

.paper-service-proposal-button-content {
    margin-top: 8px;
}

.paper-service-proposal-hr {
    margin-bottom: 8px;
    width: 100%;
    height: 1px;
    background-color: var(--gray-ligth);

}

.paper-service-proposal-service {
    font-size: var(--fs-m) !important;
}