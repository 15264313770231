.paper-provider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paper-provider-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #E5E5E5;
    padding: 3px 8px;
    border-radius: 5px;
}

.paper-provider-info {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
}

.paper-provider-info label {
    font-size: 13px;
    font-weight: bold;
}

.paper-provider-info span {
    color: #a2a2a2;
    font-size: 11px;
}

.paper-provider-icon-button {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    margin-left: 16px;
    background: transparent;
    background-image: url(../../../assets/icon/whatsapp.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.paper-provider-button-content {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.paper-provider-services-content {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paper-provider-services-content span {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 3px;
}