.layout-conteiner {
    display: flex;
    flex-direction: row;
}

.layout-content {
    margin-left: 80px;
    width: calc(100% - 80px);
    height: 100vh;
}

@media only screen and (max-width: 768px) {
    .layout-content {
        width: 100%;
        margin-left: 0px
    }
}