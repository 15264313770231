.paper-provider-dense-container {
    border: 1px solid var(--gray-ligth);
    border-radius: 10px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
}

.paper-provider-dense-container.active {
    border-color: var(--blue);
}

.paper-provider-dense-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 8px;
}

.paper-provider-dense-title {
    font-size: var(--fs-m) !important;
    font-weight: bold !important;
    color: black !important;
}
.paper-provider-dense-content span {
    color: var(--gray);
    font-weight: inherit;
    font-size: var(--fs-s);
}