.solicitations-table-container {
    max-height: calc(100% - 24px);
    overflow-y: auto;
}

/* Estilo para o corpo do scroll */
.solicitations-table-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Estilo para o trilho do scroll */
.solicitations-table-container::-webkit-scrollbar-track {
    background: #E5E5E5;
}

/* Estilo para o polegar do scroll */
.solicitations-table-container::-webkit-scrollbar-thumb {
    background-color: #009CF5;
}

/* Estilo ao passar o mouse sobre o polegar do scroll */
.solicitations-table-container::-webkit-scrollbar-thumb:hover {
    background-color: #009CF5;
}