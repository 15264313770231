.auth-container {
    background-color: var(--blue);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.auth-content {
    background-color: var(--white);
    padding: 32px;
    border-radius: 10px;
    margin-top: 32px;
    min-width: 25%;
}

.auth-header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
}

.auth-header-content h4 {
    font-size: var(--fs-m);
    margin: 0;
}

.auth-header-content h1 {
    font-size: var(--fs-l);
    margin: 0;
}

.auth-forms-content {
    margin-bottom: 16px;
}

@media only screen and (max-width: 768px) {
    .auth-content {
        width: 70%;
    }
}